const Login = () => import('@/pages/website/LoginPage.vue')
const Signup = () => import('@/pages/website/SignupPage.vue')
const ResendEmail = () => import('@/pages/website/ResendEmail.vue')
const ForgetPassword = () => import('@/pages/website/ForgetPassword.vue')

const routes = [
  {
    path: '/',
    redirect: '/login' // Redirige la ruta raíz a '/login'
  },
  {
    path: '/login',
    name: 'login',
    components: {
      default: Login, // Establece Login como la página para '/'
    }
  },
  {
    path: '/olvide-contrasena',
    name: 'forget-password',
    components: {
      default: ForgetPassword,
    },
  },
  {
    path: '/precios',
    name: 'precios',
    components: {
      default: () => import('@/pages/website/PricingPage.vue'),
    },
  },
  {
    path: '/registro',
    name: 'signup',
    components: {
      default: Signup,
    },
  },
  {
    path: '/reenviar-email',
    name: 'resend-email',
    components: {
      default: ResendEmail,
    },
  },
]

export default routes
